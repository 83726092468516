import { useState } from "react";
import { useFetchChallengesQuery } from "../../api/service/toonboxAPI";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import "./index.scss";

const AccordionItem = ({ i, expanded, setExpanded, content }) => {
  const { t } = useTranslation();
  const isOpen = i === expanded;

  return (
    <li>
      <button onClick={() => setExpanded(isOpen ? false : i)}>
        <div style={{ backgroundImage: "url(" + content.image + ")" }}>
          <h6>{content.name}</h6>
          <ul>
            <li>{content.lang_qty}</li>
            <li>{content.subscribes.toLocaleString("ru-RU")}</li>
            <li>{content.views.toLocaleString("ru-RU")}</li>
          </ul>
        </div>
        <span>
          {/* <svg fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d={
                isOpen
                  ? "M24.8449 14.2194C23.9918 15.0749 22.6068 15.0769 21.7513 14.2238L13.0001 5.49735L4.24883 14.2238C3.39335 15.0769 2.00831 15.0749 1.15525 14.2194C0.302187 13.3639 0.304152 11.9789 1.15963 11.1258L11.4555 0.859148C12.3092 0.00779915 13.6909 0.00779915 14.5447 0.859148L24.8405 11.1258C25.696 11.9789 25.6979 13.3639 24.8449 14.2194Z"
                  : "M1.15511 0.780595C2.00817 -0.074888 3.39322 -0.0768525 4.2487 0.776207L12.9999 9.50265L21.7512 0.776207C22.6066 -0.0768525 23.9917 -0.074888 24.8448 0.780595C25.6978 1.63608 25.6958 3.02112 24.8404 3.87418L14.5445 14.1409C13.6908 14.9922 12.3091 14.9922 11.4553 14.1409L1.1595 3.87418C0.304018 3.02112 0.302054 1.63608 1.15511 0.780595Z"
              }
              fill="#354EB7"
            />
          </svg> */}
          <svg style={isOpen ? {transform: "rotate(0deg)"} : {transform: "rotate(180deg)"}} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M35.0003 61.979C49.9005 61.979 61.9795 49.9 61.9795 34.9998C61.9795 20.0997 49.9005 8.02067 35.0003 8.02067C20.1001 8.02067 8.02116 20.0997 8.02116 34.9998C8.02116 49.9 20.1001 61.979 35.0003 61.979ZM66.3545 34.9998C66.3545 52.3163 52.3168 66.354 35.0003 66.354C17.6839 66.354 3.64616 52.3163 3.64616 34.9998C3.64616 17.6834 17.6839 3.64567 35.0003 3.64567C52.3168 3.64567 66.3545 17.6834 66.3545 34.9998Z" fill="#354EB7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M46.8449 40.2194C45.9918 41.0749 44.6068 41.0769 43.7513 40.2238L35.0001 31.4973L26.2488 40.2238C25.3934 41.0769 24.0083 41.0749 23.1552 40.2194C22.3022 39.3639 22.3042 37.9789 23.1596 37.1258L33.4555 26.8591C34.3092 26.0078 35.6909 26.0078 36.5447 26.8591L46.8405 37.1258C47.696 37.9789 47.6979 39.3639 46.8449 40.2194Z" fill="#354EB7"/>
          </svg>
        </span>
      </button>
      <AnimatePresence mode="wait" initial={false} key="accordionItem">
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
              transition={{ duration: 0.8 }}
            >
              <span>{t("CHANNELS LINKS")}</span>
              <ul>
                { content.channel.map((chl, idx) => (
                    <a key={idx + "chlLink"} href={chl.url} target="_blank" rel="noreferrer">{chl.name}</a>
                  )) }
              </ul>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
};


const ChallengesPage = () => {
  const { t, i18n } = useTranslation();

  const {
    data,
    isLoading,
    isError,
    isFetching
  } = useFetchChallengesQuery({
    lang: i18n.language.toLowerCase()
  });

  const [expanded, setExpanded] = useState(false);
  const isPresent = useIsPresent();

  return (
    <section className="challenges-page">
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.75, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.75, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
      <div className="challenges-page-container container">
        <h2>{t("challenges")}</h2>
        <div className="challenges-page-accordionBlock" >
          <AnimatePresence mode="wait" initial={false} key="accordionBlock">
            {
              !isLoading &&
              !isError &&
                <motion.ul
                  key={data.id ? data.id : "empty"}
                  initial={false}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -10, opacity: 0 }}
                  transition={{ duration: 0.25 }}
                >
                  {data.map((challenge, index) => (
                    <AccordionItem
                      key={index + "tabsContent"}
                      i={index}
                      content={challenge}
                      expanded={expanded}
                      setExpanded={setExpanded}
                    />
                  ))}
                </motion.ul>
            }
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default ChallengesPage;
