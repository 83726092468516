/* eslint-disable jsx-a11y/no-redundant-roles */
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion, useIsPresent } from "framer-motion";
import { useFetchAllMoviesQuery, useFetchStatisticQuery } from "../../api/service/toonboxAPI";
import AnimatedText from "../../components/AnimatedText";
import pavelPhoto from "../../assets/images/pavel-m-small.jpg";
import vladimirPhoto from "../../assets/images/vladimir-p-small.jpg";
import toonboxLogoBig from "../../assets/icons/hero.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down-white.svg";

import "./index.scss";

const MainPage = () => {
  const { t, i18n } = useTranslation();
  const heroRef = useRef(null);
  const navigate = useNavigate();
  const isPresent = useIsPresent();
  const [titleInView, setTitleInView] = useState(false);
  const [gridInView, setGridInView] = useState(false);
  const [aboutInView, setAboutInView] = useState(false);

  const {
    data: indexData,
    isLoading: isLoadingIndexData,
    isFetching: isFetchingIndexData,
    isError: isErrorIndexData,
  } = useFetchStatisticQuery();
  const {
    data: movies,
    isLoading: isLoadingMovies,
    isFetching: isFetchingMovies,
    isError: isErrorMovies,
  } = useFetchAllMoviesQuery({ lang: i18n.language.toLowerCase() });

  const placeholderText = [
    { type: "heading1", text: t("Toonbox") },
    { type: "heading1", text: t("Animation") },
    { type: "heading1", text: t("Studio") },
  ];

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.100
      }
    }
  };

  const footerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.75,
      },
    },
  };


  return (
    <section className="main-page">
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.75, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.75, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
      <div className="main-page-hero-wrapper">
        <div ref={heroRef} className="main-page-hero">
          <video
            src={(!isLoadingIndexData && !isErrorIndexData) ? indexData[0].mp4 : undefined}
            muted
            autoPlay
            loop
            playsInline
          />
          <div className="container main-page-hero-container">
            <motion.div
              className="main-page-hero-titleBlock"
              initial="hidden"
              onViewportEnter={() => {
                if (sessionStorage.hasOwnProperty("start", "preloadAnimation()")) {
                  setTitleInView(true);
                } else {
                  setTimeout(() => setTitleInView(true), 4250);
                };
              }}
              animate={titleInView ? "visible" : "hidden"}
              variants={container}
              viewport={{ once: true }}
            >
              <div className="main-page-hero-titleBlock-container">
                {placeholderText.map((item, index) => {
                  return <AnimatedText {...item} key={index} />;
                })}
              </div>
            </motion.div>
            <motion.div
              className="main-page-hero-footer"
              variants={footerVariants}
              initial={false}
              animate={titleInView ? "visible" : "hidden"}
            >
              <p>{t("The studio was founded in 2008 by Pavel Muntyan and Vladimir Ponomarev")}</p>
              <div className="main-page-hero-footer-btnsContainer">
                <motion.span
                  onClick={() => navigate("/about")}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <span className="anim-text">
                    <span data-text={t("about studio")}>{t("about studio")}</span>
                  </span>
                </motion.span>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => window.scrollTo({ top: heroRef.current.offsetHeight, behavior: "smooth" })}
                >
                  <ArrowDownIcon/>
                </motion.button>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="main-page-works container">
        <h2>{t("works")}</h2>
        <motion.div
          className="main-page-works-grid"
          onViewportEnter={() => setGridInView(true)}
        >
          {
            !isLoadingMovies &&
            !isErrorMovies &&
            movies.map((movie, idx) => (
              <motion.article
                className="main-page-works-grid-article"
                key={idx + "works"}
                onClick={() => navigate(`/works/${movie.slug}`)}
                animate={
                  gridInView
                    ? { opacity: 1, y: 0, transition: { delay: 0.25 * idx, type: "spring" }}
                    : { opacity: 0, y: 50 }
                }
                viewport={{ once: true }}
                whileHover={{ scale: 1.01, transition: { duration: 0.3 } }}
              >
                <span className="main-page-works-grid-article-episodesCounter">{movie.episodes} {t("episodes", { count: movie.episodes })}</span>
                <video
                  src={movie.mp4}
                  tabIndex={-1}
                  muted
                  loop
                  playsInline
                  poster={movie.image}
                  onMouseEnter={e => e.target.play()}
                  onMouseLeave={e => e.target.pause()}
                />
                <h3>{movie.name}</h3>
                <div className="main-page-works-grid-article-stat">
                  {movie.view !== 0 && <span>{movie.view.toLocaleString('ru-RU')}</span>}
                  {movie.likes !== 0 && <span>{movie.likes.toLocaleString('ru-RU')}</span>}
                  {movie.comments !== 0 && <span>{movie.comments.toLocaleString('ru-RU')}</span>}
                </div>
              </motion.article>
            ))
          }
        </motion.div>
        <motion.span
          onClick={() => navigate("/works")}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <span className="anim-text">
            <span data-text={t("all works")}>{t("all works")}</span>
          </span>
        </motion.span>
      </div>
      <div className="container main-page-wasFounded-container">
        <motion.div
          className="main-page-wasFounded"
          onViewportEnter={() => setAboutInView(true)}
          animate={
            aboutInView
            ? { opacity: 1, y: 0, transition: { type: "spring", delay: 0.5 }}
            : { opacity: 0, y: 150 }
          }
        >
          <div className="main-page-wasFounded-left">
            <p>{t("Toonbox studio was founded in 2008 by")} <img src={pavelPhoto} alt="Pavel Muntyan" tabIndex={-1}/> {t("Pavel Muntyan and")} <img src={vladimirPhoto} alt="Vladimir Ponomarev" tabIndex={-1}/> {t("Vladimir Ponomarev")}</p>
            <motion.span
              onClick={() => navigate("/about")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <span className="anim-text">
                <span data-text={t("more about studio")}>{t("more about studio")}</span>
              </span>
            </motion.span>
          </div>
          <div className="main-page-wasFounded-right">
            <img src={toonboxLogoBig} alt="logotype of the Toonbox" tabIndex={-1} />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default MainPage;
