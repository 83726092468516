import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchAllMoviesQuery, useFetchStatisticQuery, useFetchTeamQuery } from "../../api/service/toonboxAPI";
import { useTranslation } from "react-i18next";

import {
  useAnimationFrame,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
  useVelocity,
  motion,
  animate,
  useIsPresent } from "framer-motion";
import { wrap } from "@motionone/utils";

import { Counter } from "../../components/Counter";

import pavelPhoto from "../../assets/images/pavel-m-small.jpg";
import vladimirPhoto from "../../assets/images/vladimir-p-small.jpg";

import toonboxLogoBig from "../../assets/icons/hero.svg";

import "./index.scss";

function ParallaxText({ children, baseVelocity = 100 }) {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  const directionFactor = useRef(1);
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 2000);

    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax">
      <motion.div
        className="scroller"
        style={{ x }}
      >
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
};

const AboutPage = () => {
  const { t, i18n } = useTranslation();

  const [heroInView, setHeroInView] = useState(false);
  const [gridInView, setGridInView] = useState(false);
  const [portfolioInView, setPortfolioInView] = useState(false);
  const [totalInView, setTotalInView] = useState(false);

  const {
    data: statistic,
    isError: isErrorStatistic,
    isFetching: isFetchingStatistic,
    isLoading: isLoadingStatistic
  } = useFetchStatisticQuery();

  const {
    data: team,
    isError: isErrorTeam,
    isLoading: isLoadingTeam,
    isFetching: isFetchingTeam,
  } = useFetchTeamQuery({ lang: i18n.language.toLowerCase(), count: 100 });

  const {
    data: movies,
    isLoading: isLoadingMovies,
    isFetching: isFetchingMovies,
    isError: isErrorMovies,
  } = useFetchAllMoviesQuery({ lang: i18n.language.toLowerCase(), page: 2 });

  const navigate = useNavigate();
  const isPresent = useIsPresent();

  const count = useMotionValue(100);
  const rounded = useTransform(count, Math.round);

  useEffect(() => {
    const animation = animate(count, 480, { duration: 8, });

    return animation.stop;
  }, []);

  const handleFormat = (num) => {
    let number = num.toString();
    let firstTriple;
    let secondTriple;
    let thirdTriple;
    let fourthTriple;

    if (number.length > 3 && number.length < 7) {
      firstTriple = number.substring(number.length - 3, number.length - 6);
      secondTriple = number.substring(number.length - 3);

      return (
        <>
          <Counter num={firstTriple}/>
          <Counter num={secondTriple} flag={true}/>
        </>
      )
    } else if (number.length > 6 && number.length < 10) {
      firstTriple = number.substring(number.length - 6, number.length - 9);
      secondTriple = number.substring(number.length - 3, number.length - 6);
      thirdTriple = number.substring(number.length - 3);

      return (
        <>
          <Counter num={firstTriple}/>
          <Counter num={secondTriple} flag={true}/>
          <Counter num={thirdTriple} flag={true}/>
        </>
      )
    } else if (number.length > 9) {
      firstTriple = number.substring(number.length - 9, number.length - 12);
      secondTriple = number.substring(number.length - 6, number.length - 9);
      thirdTriple = number.substring(number.length - 3, number.length - 6);
      fourthTriple = number.substring(number.length - 3);

      return (
        <>
          <Counter num={firstTriple}/>
          <Counter num={secondTriple} flag={true}/>
          <Counter num={thirdTriple} flag={true}/>
          <Counter num={fourthTriple} flag={true}/>
        </>
      )
    } else {
      firstTriple = number.substring(0, 3);
      return <Counter num={firstTriple}/>
    };
  };

  return (
    <section className="about-page">
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.75, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.75, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
      <motion.div
        className="about-page-hero"
        onViewportEnter={() => setHeroInView(true)}
        initial={{ opacity: 0, y: 100 }}
        animate={
          heroInView
            ? { opacity: 1, y: 0, transition: { delay: 0.75, type: "spring" }}
            : { opacity: 0, y: 100 }
        }
        viewport={{ once: true }}
      >
        <div className="about-page-hero-container container">
          <p>{t("Toonbox studio was founded in 2008 by")} <img src={pavelPhoto} alt="Pavel Muntyan" tabIndex={-1}/> {t("Pavel Muntyan and")} <img src={vladimirPhoto} alt="Vladimir Ponomarev" tabIndex={-1}/> {t("Vladimir Ponomarev")}</p>
        </div>
      </motion.div>
      <motion.div
        className="about-page-raw"
        initial={{ opacity: 0 }}
        animate={
          heroInView
            ? { opacity: 1, transition: { delay: 1, type: "spring" }}
            : { opacity: 0 }
        }
        viewport={{ once: true }}
      >
        <ParallaxText baseVelocity={2}>
          {
            !isLoadingStatistic &&
            !isErrorStatistic &&
            statistic[0]?.gallery.map((image, index) => (
              <img key={index + "gallery"} src={image} alt="no-content" tabIndex={-1} />
            ))
          }
        </ParallaxText>
      </motion.div>
      <motion.div
        className="about-page-portfolio"
        onViewportEnter={() => setPortfolioInView(true)}
        initial={false}
        animate={
          portfolioInView
            ? { opacity: 1, y: 0, transition: { delay: 0.25, type: "spring" }}
            : { opacity: 0, y: 100 }
        }
        viewport={{ once: true }}
      >
        <div className="about-page-portfolio-container container">
          <img src={toonboxLogoBig} alt="this caracter" />
          <p>{t("Portfolio of the studio includes more than")} <motion.span>{rounded}</motion.span> {t("projects")}</p>
        </div>
      </motion.div>
      <div className="about-page-total-wrapper">
        <motion.div
          className="about-page-total"
          onViewportEnter={() => setTotalInView(true)}
          initial={false}
          animate={
            totalInView
              ? { opacity: 1, y: 0, transition: { delay: 0.25, type: "spring" }}
              : { opacity: 0, y: 100 }
          }
          viewport={{ once: true }}
        >
          <video
            src={(!isLoadingStatistic && !isErrorStatistic) ? statistic[0].mp4 : undefined}
            muted
            autoPlay
            loop
            playsInline
          ></video>
          <div className="about-page-total-container container">
            <motion.div
              className="about-page-total-top"
              initial={false}
              animate={
                totalInView
                  ? { opacity: 1, transition: { delay: 0.75, type: "spring", duration: 1 }}
                  : { opacity: 0 }
              }
              viewport={{ once: true }}
            >
              <h6>{t("Total views count")} </h6>
              {
                !isLoadingStatistic &&
                !isErrorStatistic &&
                totalInView &&
                handleFormat(statistic[0].total_view)
              }
            </motion.div>
            <motion.div
              className="about-page-total-bottom"
              initial={false}
              animate={
                totalInView
                  ? { opacity: 1, y: 0, transition: { delay: 1.25, type: "spring" }}
                  : { opacity: 0, y: 50 }
              }
              viewport={{ once: true }}
            >
              <p>{t("A great number of the projects gained popularity due to unique quality and creative approach of Toonbox team.")}</p>
            </motion.div>
          </div>
        </motion.div>
      </div>
      <div className="about-page-people">
        <div className="about-page-people-container container">
          <h2>{t("key people")}</h2>
          <div className="about-page-people-grid">
            {
              !isLoadingTeam &&
              !isErrorTeam &&
              team.map((member, idx) => (
                <article key={idx + "teamMember"}>
                  <img src={member.image} alt={member.name} />
                  <h5>{member.name}</h5>
                  <span>{member.job}</span>
                </article>
              ))
            }
          </div>
        </div>
      </div>
      <div className="about-page-works">
        <div className="about-page-works-container container">
          <h2>{t("works")}</h2>
          <motion.div
            className="main-page-works-grid"
            onViewportEnter={() => setGridInView(true)}
          >
          {
            !isLoadingMovies &&
            !isErrorMovies &&
            movies.map((movie, idx) => (
              <motion.article
                className="main-page-works-grid-article"
                key={idx + "works"}
                onClick={() => navigate(`/works/${movie.slug}`)}
                animate={
                  gridInView
                    ? { opacity: 1, y: 0, transition: { delay: 0.25 * idx, type: "spring" }}
                    : { opacity: 0, y: 50 }
                }
                viewport={{ once: true }}
                whileHover={{ scale: 1.01, transition: { duration: 0.3 } }}
              >
                <span className="main-page-works-grid-article-episodesCounter">{movie.episodes} {t("episodes", { count: movie.episodes })}</span>
                <video
                  src={movie.mp4}
                  tabIndex={-1}
                  muted
                  loop
                  playsInline
                  poster={movie.image}
                  onMouseEnter={e => e.target.play()}
                  onMouseLeave={e => e.target.pause()}
                />
                <h3>{movie.name}</h3>
                <div className="main-page-works-grid-article-stat">
                  {movie.view !== 0 && <span>{movie.view.toLocaleString('ru-RU')}</span>}
                  {movie.likes !== 0 && <span>{movie.likes.toLocaleString('ru-RU')}</span>}
                  {movie.comments !== 0 && <span>{movie.comments.toLocaleString('ru-RU')}</span>}
                </div>
              </motion.article>
            ))
          }
          </motion.div>
          <motion.span
            className="about-page-works-btn"
            onClick={() => navigate("/works")}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <span className="anim-text">
              <span data-text={t("all works")}>{t("all works")}</span>
            </span>
          </motion.span>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
