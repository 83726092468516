import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCycle, motion } from "framer-motion";
import "./index.scss";

const useDimensions = ref => {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    dimensions.current.width = ref.current.offsetWidth;
    dimensions.current.height = ref.current.offsetHeight;
  }, []);

  return dimensions.current;
};

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height}px at 200px)`,
    backgroundColor: "rgb(53, 78, 183)",
    // backgroundColor: "rgba(53, 78, 183, 1)",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 200px)",
    backgroundColor: "transparent",
    // backgroundColor: "rgba(53, 78, 183, 0)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const variantsNav = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const Navigation = ({ toggle }) => (
  <motion.ul variants={variantsNav}>
    {itemIds.map(i => (
      <MenuItem toggle={toggle} i={i} key={i} />
    ))}
  </motion.ul>
);

const itemIds = ["works", "challenges", "about"];

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#354EB7"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }) => (
  <button onClick={toggle}>
    <svg width="20" height="20" viewBox="0 0 20 20">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </svg>
  </button>
);

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const colors = ["#354EB7", "#354EB7", "#354EB7", "#354EB7", "#354EB7"];

export const MenuItem = ({ i, toggle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => {
        toggle();
        navigate(`/${i}`);
      }}
    >
      {t(i)}
    </motion.li>
  );
};

const HeaderComponent = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  function changeLang(e) {
    i18n
    .changeLanguage(e)
    .then(() => {
      i18n.options.lng = e;
      localStorage.setItem("lan", e);
    })
  };

  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        className="header-nav-mobile"
        style={isOpen ? { pointerEvents: "all" } : { pointerEvents: "none" }}
      >
        <motion.div className="header-nav-mobile-background" variants={sidebar} />
        <Navigation toggle={() => toggleOpen()} />
        <MenuToggle toggle={() => toggleOpen()} />
      </motion.nav>
      <header className="header">
        <div className="header-logoContainer">
          <div onClick={() => navigate("/")}></div>
        </div>
        <div className="header-nav">
          <ul className="header-nav-left">
            <li>
              <Link to="/works">
                <span className="anim-text">
                  <span data-text={t("works")}>{t("works")}</span>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/challenges">
                <span className="anim-text">
                  <span data-text={t("challenges")}>{t("challenges")}</span>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/about">
                <span className="anim-text">
                  <span data-text={t("about")}>{t("about")}</span>
                </span>
              </Link>
            </li>
          </ul>
          <div className="header-nav-right">
            <button onClick={(e) => changeLang(e.target.firstChild.textContent)}>
              <span className="anim-text">
                <span data-text={t("RU")}>{t("RU")}</span>
              </span>
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderComponent;
