import React, { useEffect } from "react";
import { animate, useMotionValue, useTransform, motion } from "framer-motion";

export const Counter = React.memo(({ num, flag = false }) => {
  const count = useMotionValue(0); 
  const rounded = useTransform(count, Math.round);

  useEffect(() => {
    const animation = animate(count, Number(num), { duration: 3 });

    return animation.stop;
  }, []);

  return (
    <span className="counter">
      <span>{flag && num.startsWith("00") ? "00" : num.startsWith("0") ? "0" : ""}</span>
      <motion.span>{rounded}</motion.span>
    </span>
  )
});
