import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchMovieQuery } from "../../api/service/toonboxAPI";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import { Counter } from "../../components/Counter";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down-white.svg";
import "./index.scss";

const SingleWorkPage = ({ movie }) => {
  const { t, i18n } = useTranslation();
  const {isError, isLoading, data} = useFetchMovieQuery({ name: movie.slug, lang: i18n.language.toLowerCase() });
  const [selectedTab, setSelectedTab] = useState("episodes");
  const heroRef = useRef(null);
  const isPresent = useIsPresent();
  const navigate = useNavigate();
  const [titleInView, setTitleInView] = useState(false);
  const [heroInView, setHeroInView] = useState(false);

  const handleFormat = (num) => {
    let number = num.toString();
    let firstTriple;
    let secondTriple;
    let thirdTriple;

    if (number.length > 3 && number.length < 7) {
      firstTriple = number.substring(number.length - 3, number.length - 6);
      secondTriple = number.substring(number.length - 3);

      return (
        <>
          <Counter num={firstTriple}/>
          <Counter num={secondTriple} flag={true}/>
        </>
      )
    } else if (number.length > 6) {
      firstTriple = number.substring(number.length - 6, number.length - 9);
      secondTriple = number.substring(number.length - 3, number.length - 6);
      thirdTriple = number.substring(number.length - 3);

      return (
        <>
          <Counter num={firstTriple}/>
          <Counter num={secondTriple} flag={true}/>
          <Counter num={thirdTriple} flag={true}/>
        </>
      )
    } else {
      firstTriple = number.substring(0, 3);
      return <Counter num={firstTriple}/>
    };
  };

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.045
      }
    }
  };

  return (
    <section className="singleWork-page">
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.75, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.75, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
      <div className="singleWork-page-hero-wrapper">
        <div ref={heroRef} className="singleWork-page-hero">
          <video
            src={movie?.mp4}
            poster={movie?.image}
            muted
            autoPlay
            loop
            playsInline
          />
          <motion.div
            style={movie.name.length > 45 ? { height: "70%" } : {}}
            className="container singleWork-page-hero-container"
            onViewportEnter={() => setHeroInView(true)}
            initial={{ opacity: 0, y: 100 }}
            animate={
              heroInView
                ? { opacity: 1, y: 0, transition: { delay: 0.75, type: "spring" }}
                : { opacity: 0, y: 100 }
            }
            viewport={{ once: true }}
          >
            <div className="singleWork-page-hero-content">
              <motion.div
                className="singleWork-page-hero-content-titleBlock"
                initial="hidden"
                onViewportEnter={() => setTitleInView(true)}
                animate={titleInView ? "visible" : "hidden"}
                variants={container}
                viewport={{ once: true }}
              >
                <div className="singleWork-page-hero-content-titleBlock-container">
                  <h1>{movie.name}</h1>
                </div>
              </motion.div>
              <div className="singleWork-page-hero-content-stat">
                <div className="singleWork-page-hero-content-stat-numBlock">
                  {movie.view !== 0 && handleFormat(movie.view)}
                </div>
                <div className="singleWork-page-hero-content-stat-numBlock">
                  {movie.likes !== 0 && handleFormat(movie.likes)}
                </div>
                <div className="singleWork-page-hero-content-stat-numBlock">
                  {movie.comments !== 0 && handleFormat(movie.comments)}
                </div>
              </div>
            </div>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => window.scrollTo({ top: heroRef.current.offsetHeight, behavior: "smooth" })}
            >
              <ArrowDownIcon/>
            </motion.button>
          </motion.div>
        </div>
      </div>
      <div className="singleWork-page-content container">
        <ul className="singleWork-page-content-tabs">
          <li>
            <button
              className={selectedTab === "episodes" ? "active" : ""}
              onClick={() => setSelectedTab("episodes")}
            >{t("episodes ")}</button>
          </li>
          {
            !isLoading &&
            !isError &&
            data[0]?.content !== (null || "") &&
              <li>
                <button
                  className={selectedTab === "about" ? "active" : ""}
                  onClick={() => setSelectedTab("about")}
                >
                  {t("about cartoon")}
                </button>
              </li>
          }
        </ul>
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            className="singleWork-page-content-container"
            key={selectedTab ? selectedTab : "empty"}
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {
              selectedTab === "episodes"
                ? (
                  <div className="singleWork-page-content-grid">
                    {
                      !isLoading &&
                      !isError &&
                      data[0]?.episodes.map((episode, idx) => (
                        <motion.a
                          key={idx + "SPEpisode"}
                          className={
                            episode.youtube_id !== (null || "")
                              ? "singleWork-page-content-grid-article"
                              : "singleWork-page-content-grid-article singleWork-page-content-grid-article-without-play"
                          }
                          whileHover={{ scale: 1.01, transition: { duration: 0.3 } }}
                          style={episode.youtube_id === (null || "") && { pointerEvents: "none" }}
                          href={`https://youtube.com/watch?v=${episode.youtube_id}`}
                          target="_blank"
                        >
                          {/* <span className="singleWork-page-content-grid-article-timeCounter">5 MIN</span> */}
                          <img src={episode.image} alt={episode.name} tabIndex={-1} />
                          <h3>{episode.name}</h3>
                          <div className="singleWork-page-content-grid-article-stat">
                            {episode.views != null && episode.views !== 0 && <span>{episode.views.toLocaleString("ru-RU")}</span>}
                            {episode.likes != null && episode.likes !== 0 && <span>{episode.likes.toLocaleString("ru-RU")}</span>}
                            {episode.comments != null && episode.comments !== 0 && <span>{episode.comments.toLocaleString("ru-RU")}</span>}
                          </div>
                        </motion.a>
                      ))
                    }
                  </div>
                ) : (
                  <div className="singleWork-page-content-aboutCartoon">
                    <h4>{movie.name}</h4>
                    <p dangerouslySetInnerHTML={ {__html: data[0]?.content !== null && data[0]?.content} }></p>
                    {/* <p>{data[0]?.content !== null ? data[0]?.content : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque quae voluptas numquam, aliquam id esse! Eos animi dolores excepturi quos vitae dolor ratione quod. Dolorum veniam, minima, et ratione neque quas unde culpa reiciendis voluptatem eligendi debitis eos blanditiis rerum fugiat sunt modi id ullam cumque odio labore doloremque. Tempore."}</p> */}
                  </div>
                )
            }
          </motion.div>
        </AnimatePresence>
      </div>
      { !isLoading &&
        !isError &&
        data[0]?.next.id &&
        data[0]?.next.id !== (null || "") &&
          <div className="singleWork-page-nextWork container">
            <h3>{t("next work")}</h3>
            <motion.article
              className="singleWork-page-nextWork-article"
              whileHover={{ scale: 1.01, transition: { duration: 0.3 } }}
            >
              <div className="singleWork-page-nextWork-article-left">
                <span>{data[0].next.episodes} {t("episodes", { count: data[0].next.episodes })}</span>
                <h4>{data[0].next.name}</h4>
                <div>
                  {data[0].next.view !== 0 && <span>{data[0].next.view.toLocaleString('ru-RU')}</span>}
                  {data[0].next.likes !== 0 && <span>{data[0].next.likes.toLocaleString('ru-RU')}</span>}
                  {data[0].next.comments !== 0 && <span>{data[0].next.comments.toLocaleString('ru-RU')}</span>}
                </div>
              </div>
              <div
                className="singleWork-page-nextWork-article-right"
                onClick={() => navigate(`/works/${data[0].next.slug}`)}
              >
                <img
                  src={data[0].next.image}
                  alt={data[0].next.name}
                />
              </div>
            </motion.article>
          </div>
      }
    </section>
  );
};

export default SingleWorkPage;
