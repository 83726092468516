import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const toonboxAPI = createApi({
  reducerPath: 'toonboxAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.toonbox.studio/api",
  }),
  tagTypes: ['Movie'],
  endpoints: (build) => ({
    fetchAllMovies: build.query({
      query: ({ lang, page = 1, count = 6 }) => `/movie?page=${page}&count=${count}&lang=${lang}`,
      providesTags: ['Movie'],
    }),
    fetchMovie: build.query({
      query: ({ name, lang }) => `/movie/${name}?lang=${lang}`,
      providesTags: ['Movie'],
    }),
    fetchStatistic: build.query({
      query: () => `/index`,
      providesTags: ['Movie'],
    }),
    fetchContacts: build.query({
      query: () => `/footer`,
      providesTags: ['Movie'],
    }),
    fetchTeam: build.query({
      query: ({ lang, page = 1, count = 12 }) => `/team?page=${page}&count=${count}&lang=${lang}`,
      providesTags: ['Movie'],
    }),
    fetchChallenges: build.query({
      query: ({ lang, page = 1, count = 12 }) => `/challenge?page=${page}&count=${count}&lang=${lang}`,
      providesTags: ['Movie'],
    }),
  }),
});

export const {
  useFetchAllMoviesQuery,
  useFetchMovieQuery,
  useFetchContactsQuery,
  useFetchStatisticQuery,
  useFetchTeamQuery,
  useFetchChallengesQuery,
} = toonboxAPI;
