import React, { useEffect } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { useFetchAllMoviesQuery } from "./api/service/toonboxAPI";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import MainPage from "./pages/Main";
import AboutPage from "./pages/About";
import WorksPage from "./pages/Works";
import ChallengesPage from "./pages/Challenges";
import SingleWorkPage from "./pages/SingleWork";

const checkWindowHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const handleResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  window.addEventListener("resize", handleResize);

  return () => window.removeEventListener("resize", handleResize);
};

const App = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const {
    data: movies,
    isLoading: isLoadingMovies,
    isFetching: isFetchingMovies,
    isError: isErrorMovies,
  } = useFetchAllMoviesQuery({
    lang: i18n.language.toLowerCase(),
    count: 100
  });

  const singlePages = [];

  movies?.map((movie) => {
    singlePages.push({
      path: `/works/${movie.slug}`,
      element: <SingleWorkPage movie={movie} />
    })
  })

  const element = useRoutes([
    {
      path: "/",
      element: <MainPage />
    },
    {
      path: "*",
      element: <Navigate to="/" replace />
    },
    {
      path: "/about",
      element: <AboutPage />
    },
    {
      path: "/challenges",
      element: <ChallengesPage />
    },
    {
      path: "/works",
      element: <WorksPage />
    },
    ...singlePages,
  ]);

  useEffect(() => {
    checkWindowHeight();
  }, []);

  return (
    <AnimatePresence
      mode="wait"
      initial={false}
      onExitComplete={() => {
        if (typeof window !== "undefined") {
          window.scrollTo({ top: 0, behavior: "instant" });
        }
      }}
    >
      {React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
};

export default App;
