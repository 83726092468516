import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetchAllMoviesQuery } from "../../api/service/toonboxAPI";
import { motion, useIsPresent } from "framer-motion";
import "./index.scss";

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};

const WorksPage = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isPresent = useIsPresent();
  const [gridInView, setGridInView] = useState(false);

  const {
    data: movies,
    isLoading: isLoadingMovies,
    isFetching: isFetchingMovies,
    isError: isErrorMovies,
  } = useFetchAllMoviesQuery({
    lang: i18n.language.toLowerCase(),
    count: 100
  });

  return (
    <section className="works-page">
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.75, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.75, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        className="works-page-nav"
      >
        <motion.button
          whileTap={{ scale: 0.97 }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {t("all works")}
          <motion.div
            variants={{
              open: { rotate: 180 },
              closed: { rotate: 0 }
            }}
            transition={{ duration: 0.2 }}
            style={{ originY: 0.55 }}
          >
          <svg fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.15511 0.780595C2.00817 -0.074888 3.39322 -0.0768525 4.2487 0.776207L12.9999 9.50265L21.7512 0.776207C22.6066 -0.0768525 23.9917 -0.074888 24.8448 0.780595C25.6978 1.63608 25.6958 3.02112 24.8404 3.87418L14.5445 14.1409C13.6908 14.9922 12.3091 14.9922 11.4553 14.1409L1.1595 3.87418C0.304018 3.02112 0.302054 1.63608 1.15511 0.780595Z" fill="#354EB7"/>
          </svg>
          </motion.div>
        </motion.button>
        <motion.div
          variants={{
            open: {
              clipPath: "inset(0% 0% 0% 0% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.4,
                delayChildren: 0.3,
                staggerChildren: 0.05
              }
            },
            closed: {
              clipPath: "inset(10% 50% 90% 50% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3
              }
            }
          }}
          style={{ pointerEvents: isOpen ? "auto" : "none" }}
        >
          <motion.ul>
            {
              !isLoadingMovies &&
              !isErrorMovies &&
              movies.map((movie, idx) => (
                <motion.li
                  key={idx + "worksListItem"}
                  variants={itemVariants}
                  className="anim-text"
                  onClick={() => navigate(`/works/${movie.slug}`)}
                >
                  <span data-text={movie.name}>{movie.name}</span>
                </motion.li>
              ))
            }
          </motion.ul>
        </motion.div>
      </motion.nav>
      <motion.div
        className="works-page-grid container"
        onViewportEnter={() => setGridInView(true)}
        initial={false}
        animate={
          gridInView
            ? { opacity: 1, y: 0, transition: { delay: 0.75, type: "spring" }}
            : { opacity: 0, y: 250 }
        }
        viewport={{ once: true }}
      >
        {
          !isLoadingMovies &&
          !isErrorMovies &&
          movies.map((movie, idx) => (
            <motion.article
              key={idx + "worksArticle"}
              className="works-page-grid-article"
              onClick={() => navigate(`/works/${movie.slug}`)}
              whileHover={{ scale: 1.01, transition: { duration: 0.3 } }}
            >
              <span className="works-page-grid-article-episodesCounter">{movie.episodes} {t("episodes", { count: movie.episodes })}</span>
              <video
                src={movie.mp4}
                tabIndex={-1}
                muted
                loop
                playsInline
                poster={movie.image}
                onMouseEnter={e => e.target.play()}
                onMouseLeave={e => e.target.pause()}
              />
              <h3>{movie.name}</h3>
              <div className="works-page-grid-article-stat">
                {movie.view !== 0 && <span>{movie.view.toLocaleString('ru-RU')}</span>}
                {movie.likes !== 0 && <span>{movie.likes.toLocaleString('ru-RU')}</span>}
                {movie.comments !== 0 && <span>{movie.comments.toLocaleString('ru-RU')}</span>}
              </div>
            </motion.article>
          ))
        }
      </motion.div>
    </section>
  );
};

export default WorksPage;
