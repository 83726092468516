import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import { setupStore } from "./api/store";
import { Provider as ReduxProvider } from "react-redux";

import i18next from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import "./i18nxt";

import FooterComponent from "./components/Footer";
import HeaderComponent from "./components/Header";
import PreloaderComponent from "./components/Preloader";

import "./index.css";

const i18n = i18next.use(initReactI18next);
const store = setupStore();

function AppWithUI() {
  return (
    <BrowserRouter>
      <PreloaderComponent/>
      <main className="main">
        <HeaderComponent/>
        <App />
        <FooterComponent/>
      </main>
    </BrowserRouter>
  );
};

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);
root.render(
  <ReduxProvider store={store}>
    <I18nextProvider i18n={i18n}>
      <AppWithUI />
    </I18nextProvider>
  </ReduxProvider>
);
