import { useRef, useEffect, useLayoutEffect } from "react";
import preloaderImg from "../../assets/icons/hero.svg";
import "./index.scss";

const PreloaderComponent = () => {
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    document.body.style.overflowY = "hidden";
    if (sessionStorage.hasOwnProperty("start", "preloadAnimation()")) {
      containerRef.current.remove();
      document.body.style.overflowY = "auto";
    }
  }, []);

  useEffect(() => {
    const preloadEnd = (e) => {
      document.body.style.overflowY = "auto";
      sessionStorage.setItem("start", "preloadAnimation()");
      const removePreloader = () => containerRef.current.remove();
      setTimeout(removePreloader, 2000);
      containerRef.current.classList.add("hiddenContainer");
      setTimeout(() => {
        preloadEnd();
      }, 4000);
    };
    setTimeout(() => preloadEnd(), 4000);
  }, []);

  return (
    <div ref={containerRef} className="preloader-container">
      <div className="preloader-container-block">
        <img src={preloaderImg} alt="prelaoder" />
        <div>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default PreloaderComponent;
