import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { toonboxAPI } from "./service/toonboxAPI";

const rootReducer = combineReducers({
  [toonboxAPI.reducerPath]: toonboxAPI.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        toonboxAPI.middleware,
      ]),
  });
};
