import { useRef, useState } from "react";
import { useFetchContactsQuery } from "../../api/service/toonboxAPI";
import { useAnimationFrame, useMotionValue, useScroll, useSpring, useTransform, useVelocity, motion } from "framer-motion";
import { wrap } from "@motionone/utils";
import logotype from "../../assets/icons/logotype.svg";
import { ReactComponent as ArrowTopIcon } from "../../assets/icons/arrow-top-blue.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import "./index.scss";

function ParallaxText({ children, baseVelocity = 100 }) {
  const [stop, setStop] = useState(false);
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  const directionFactor = useRef(1);
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 2000);

    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax">
      <motion.div
        className="scroller"
        style={ stop ? { x: 0 } : { x }}
        onMouseEnter={() => setStop(true)}
        onMouseLeave={() => setStop(false)}
      >
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
};

const FooterComponent = () => {
  const { data, isError, isLoading, isFetching } = useFetchContactsQuery();

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-top-left">
          <ul>
            <li>
              <motion.a
                href={(!isFetching && !isLoading && !isError) ? data[3].link : "https://facebook.com/"}
                target="_blank"
                rel="noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <FacebookIcon/>
              </motion.a>
            </li>
            <li>
              <motion.a
                href={(!isFetching && !isLoading && !isError) ? data[2].link : "https://instagram.com/"}
                target="_blank"
                rel="noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <InstagramIcon/>
              </motion.a>
            </li>
          </ul>
        </div>
        <div className="footer-top-right">
          <motion.button
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ArrowTopIcon/>
          </motion.button>
        </div>
      </div>
      <div className="footer-middle">
        <img src={logotype} alt="logotype" tabIndex={-1} />
      </div>
      <div className="footer-bottom">
        <ParallaxText baseVelocity={-5}>
          {
            !isFetching &&
            !isLoading &&
            !isError &&
            data.map((contact, index) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={index + "footerLinks"}
                href={
                  contact.type === "mail"
                    ? `mailto:${contact.link}`
                    : contact.type === "tel"
                      ? contact.type + ":" + contact.link
                      : contact.type === "link"
                        ? contact.link
                        : null
                }
                rel={contact.type === "link" ? "noreferrer" : ""}
                target={contact.type === "link" ? "_blank" : "_self"}
                className="anim-text"
              >
                <span data-text={contact.name}>{contact.name}</span>
              </a>
            ))
          }
          {/* <a href="tel:+35796505700" className="anim-text"><span data-text="+357 (96) 505-700">+357 (96) 505-700</span></a>
          <a href="https://instagram.com" target="_blank" rel="noreferrer" className="anim-text"><span data-text="INSTAGRAM">INSTAGRAM</span></a>
          <a href="https://facebook.com" target="_blank" rel="noreferrer" className="anim-text"><span data-text="FACEBOOK">FACEBOOK</span></a>
          <span className="anim-text"><span data-text="Toonbox (Cyprus) Ltd.">Toonbox (Cyprus) Ltd.</span></span>
          <a href="mailto:producer@toonbox.studio" className="anim-text"><span data-text="producer@toonbox.studio">producer@toonbox.studio</span></a> */}
        </ParallaxText>
      </div>
    </footer>
  );
};

export default FooterComponent;
